
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";
  import { activityIsDossier } from "@/utils/model-helpers";
  import { MyWorkActivityActionLabelTypes } from "@/enums/my-work";
  import { parse } from "fecha";
  import { ActivityActionLabel, getActionLabelBasedOnActivity } from "@/utils/activities/action-label-helpers";

  // @ts-ignore - This is a valid import
  interface TooltipActionLabel extends Omit<MyWorkActivityActionLabel, "id" | "activity_id"> {}

  @Component
  export default class DossierAction extends Vue {
    @Prop() activity!: Activity;

    @Prop({ default: "corner" }) theme!: "bar" | "corner";

    /**
     * @name Is activity a dossier
     * @description Check if the current activity is a dossier, if not it will be considerded a prospect
     *
     * @returns {boolean} isDossier
     */
    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get activityLabel() {
      const label = getActionLabelBasedOnActivity(this.activity);
      // If there are no actions we can assume the activity is unscheduled
      if (label.value === ActivityActionLabel.Unscheduled) {
        return {
          label: "UNSCHEDULED",
          title: "Er is geen afspraak én geen Follow-up gepland.",
          deadline: "",
          same_label_task_count: 0,
        };
      }

      // if there is either a task or an an appointment due we will consider the activity as today
      if (label.value === ActivityActionLabel.Due) {
        return {
          label: "DUE",
          title: label.action.title ?? "Er is een Follow-up vandaag.",
          deadline: label.action.deadline,
          same_label_task_count: 0,
        };
      }

      // If there is either a task or an an appointment today we will consider the activity as today
      if (label.value === ActivityActionLabel.Today && label.type === "task") {
        return {
          label: "TODAY",
          title: label.action.title ?? "Er is een Follow-up vandaag.",
          deadline: label.action.deadline,
          same_label_task_count: 0,
        };
      }

      if (label.value === ActivityActionLabel.Today && label.type === "appointment") {
        return {
          label: "TODAY",
          title: label.action.title ?? "Er is een afspraak vandaag.",
          deadline: label.action.deadline,
          same_label_task_count: 0,
        };
      }

      // if there is either a task in the future or an appointment in the future
      // we will consider the activity as scheduled
      if (label.value === ActivityActionLabel.Scheduled && label.type === "task") {
        return {
          label: "SCHEDULED",
          title: label.action.title ?? "Er is een Follow-up gepland.",
          deadline: label.action.deadline,
          same_label_task_count: 0,
        };
      }

      if (label.value === ActivityActionLabel.Scheduled && label.type === "appointment") {
        return {
          label: "SCHEDULED",
          title: label.action.title ?? "Er is een afspraak gepland.",
          deadline: label.action.deadline,
          same_label_task_count: 0,
        };
      }

      return {
        label: "UNSCHEDULED",
        title: "Er is geen afspraak én geen Follow-up gepland.",
        deadline: "",
        same_label_task_count: 0,
      };
    }

    /**
     * Generate the tooltip for the activity label, will return false if no label is found
     *
     * @param {MyWorkActivityActionLabel | false} object
     *
     * @returns {object}
     */
    generateTooltip(object?: TooltipActionLabel | false) {
      if (!object || !object.title) {
        return;
      }

      let html = `<div class="small-tooltip">`;
      if (object.same_label_task_count === 0) {
      } else if (object.same_label_task_count === 1) {
        if (object.deadline) {
          html += `<div class="small-tooltip-title">Taak</div>`;
        } else {
          html += `<div class="small-tooltip-title">Taak zonder deadline</div>`;
        }
      } else {
        html += `<div class="small-tooltip-title">${object.same_label_task_count} Taken</div>`;
      }

      if (object.title) {
        if (object.same_label_task_count === 0) {
          html += `<div class="small-tooltip-content">${object.title}</div>`;
        } else if (object.same_label_task_count === 1) {
          html += `<div class="small-tooltip-content">${object.title}</div>`;
        } else {
          html += `<div class="small-tooltip-content">Volgende taak: ${object.title}</div>`;
        }
      }

      if (object.deadline) {
        html += `<div class="small-tooltip-footer">${formatDate(object.deadline, "dddd D MMMM YYYY")}</div>`;
      }

      html += "</div>";
      return {
        content: html,
        html: true,
        classes: ["small-tooltip"],
        offset: [0, 12],
        delay: { show: 0, hide: 300 },
        placement: "right",
      };
    }
  }
